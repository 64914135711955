'use client'

import {
  forwardRef,
  type ElementRef,
  type ComponentPropsWithoutRef,
} from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { tooltipContentVariants } from './tooltip.variants'

const TooltipProvider = TooltipPrimitive.Provider

const Root = TooltipPrimitive.Root

const Trigger = TooltipPrimitive.Trigger

const Content = forwardRef<
  ElementRef<typeof TooltipPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 8, ...props }, ref) => {
  return (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={tooltipContentVariants({ className })}
      {...props}
    />
  )
})

Content.displayName = TooltipPrimitive.Content.displayName

export { Root, Trigger, Content, TooltipProvider }
