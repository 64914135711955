import {
  tv as tailwindVariants,
  type TV,
  type VariantProps,
} from 'tailwind-variants'

const tv: TV = (options, config) => {
  return tailwindVariants(options, {
    ...config,
    twMerge: config?.twMerge ?? true,
    twMergeConfig: {
      ...config?.twMergeConfig,
      theme: {
        ...config?.twMergeConfig?.theme,
      },
      classGroups: {
        ...config?.twMergeConfig?.classGroups,
        // ['font-size']: [
        //   {
        //     text: Object.keys(theme.fontSize),
        //   },
        // ],
      },
    },
  })
}

const replaceWithMarkup = (str: string, secondColor = 'text-orange-500') => {
  return str
    .replace(/\|/g, `<br class="block" />`)
    .replace(/\*\*(.*?)\*\*/g, `<span class="${secondColor}">$1</span>`)
}

export { type VariantProps, tv, replaceWithMarkup }
