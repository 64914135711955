import { tv, type VariantProps } from '../../lib/utils'

const tooltipContentVariants = tv({
  base: [
    'z-50',
    'px-2 py-1',
    'overflow-hidden',
    'rounded-md',
    'border border-gray-100',
    'bg-white',
    'text-sm text-gray-500',
    'shadow-sm',
    'animate-in fade-in-0 zoom-in-95',
    'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95',
    'data-[side=bottom]:slide-in-from-top-2',
    'data-[side=left]:slide-in-from-right-2',
    'data-[side=right]:slide-in-from-left-2 ',
    'data-[side=top]:slide-in-from-bottom-2',
  ],
})

export type TooltipContentVariantProps = VariantProps<
  typeof tooltipContentVariants
>

export { tooltipContentVariants }
